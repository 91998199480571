import {createAction, props} from '@ngrx/store';


export class FakturierungsbelegFormActions {

  public static selectLeistungsempfaenger = createAction(
    '[Fakturierungsbeleg Form] Select Leistungsempfänger',
    props<{
      absenderId?: string,
    }>(),
  );

}
