<div
  class="formfields-absender-wrapper"
  *ngIf="formControls && anredenOptions && laenderOptions"
>
<mat-divider class="topline"></mat-divider>
  <jf-form-field-text
    class="firma"
    [formControl]="formControls.firma"
    [label]="'Firma'">
  </jf-form-field-text>
  <jf-form-field-number
    class="kundennummer"
    [message]="formControls.kundennummer.touched ? '' : 'Vorgeschlagene Kundennummer'"
    [formControl]="formControls.kundennummer"
    [label]="'Kundennummer'">
  </jf-form-field-number>
  <jf-form-field-text
    class="strasse"
    [formControl]="formControls.strasseundhausnummer"
    [label]="'Strasse + Hausnummer'">
  </jf-form-field-text>
  <jf-form-field-text
    class="plz"
    [formControl]="formControls.plz"
    [label]="'PLZ'">
  </jf-form-field-text>
  <jf-form-field-text
    class="ort"
    [formControl]="formControls.ort"
    [label]="'Ort'">
  </jf-form-field-text>
  <jf-form-field-select
    class="land"
    [formControl]="formControls.landGeschaeft"
    [options]="laenderOptions!"
    [label]="'Land'"></jf-form-field-select>
  <jf-form-field-text
    class="ustid"
    [message]="'Nur auserhalb von Deutschland'"
    [formControl]="formControls.ustId"
    [label]="'USt-ID'"></jf-form-field-text>
  <jf-form-field-text
    class="email"
    [formControl]="formControls.email"
    [label]="'E-Mail-Adresse'"></jf-form-field-text>

</div>
<mat-divider class="inbetween"></mat-divider>

<h2>Ansprechpartner (optionale Angaben)</h2>

<div
  class="formfields-ansprechpartner-wrapper"
  *ngIf="formControls && anredenOptions && laenderOptions"
>
  <jf-form-field-select
    class="anrede"
    [label]="'Anrede'"
    [formControl]="formControls.ansprechpartnerAnrede"
    [options]="anredenOptions || []"
  ></jf-form-field-select>
  <jf-form-field-text
    class="titel"
    [label]="'Titel'"
    [formControl]="formControls.ansprechpartnerTitel"></jf-form-field-text>
  <jf-form-field-text
    class="vorname"
    [label]="'Vorname'"
    [formControl]="formControls.ansprechpartnerVorname"></jf-form-field-text>
  <jf-form-field-text
    class="nachname"
    [formControl]="formControls.ansprechpartnerNachname"
    [label]="'Nachname'"></jf-form-field-text>
</div>
