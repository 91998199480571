<div
  class="formfield-wrapper"
  *ngIf="formControls && anredenOptions && laenderOptions"
>
  <mat-divider class="topline"></mat-divider>

  <jf-form-field-select
    class="anrede"
    [label]="'Anrede'"
    [options]="anredenOptions"
    [formControl]="formControls.anrede"
  >
  </jf-form-field-select>
  <jf-form-field-text
    [formControl]="formControls.titel"
    [label]="'Titel'"
    class="titel"
  >
  </jf-form-field-text>
  <jf-form-field-number
    class="kundennummer"
    [label]="'Kundennummer'"
    [message]="formControls.kundennummer.touched ? '' : 'Vorgeschlagene Kundennummer'"
    [formControl]="formControls.kundennummer"
  >
  </jf-form-field-number>
  <jf-form-field-text
    class="vorname"
    [label]="'Vorname'"
    [formControl]="formControls.vorname"
  >
  </jf-form-field-text>
  <jf-form-field-text
    class="nachname"
    [label]="'Nachname'"
    [formControl]="formControls.nachname"
  >
  </jf-form-field-text>
  <jf-form-field-text
    class="strasse"
    [label]="'Straße + Hausnummer'"
    [formControl]="formControls.strasseundhausnummer"
  >
  </jf-form-field-text>
  <jf-form-field-text
    class="plz"
    [label]="'PLZ'"
    [formControl]="formControls.plz"
  >
  </jf-form-field-text>
  <jf-form-field-text
    class="ort"
    [label]="'Ort'"
    [formControl]="formControls.ort"
  >
  </jf-form-field-text>
  <jf-form-field-select
    class="land"
    [label]="'Land'"
    [options]="laenderOptions"
    [formControl]="formControls.landPrivat"
  >
  </jf-form-field-select>
  <jf-form-field-text
    class="email"
    [label]="'E-Mail-Adresse'"
    [formControl]="formControls.email"
  >
  </jf-form-field-text>
</div>
