import {EntityState} from '@ngrx/entity';
import {ProduktDTO} from '../../openapi/fakturierung-openapi';
import {produktBerechnungsartenMock, produktEinheitenMock, produktUstSaetzeMock} from './produkt-dialog.mock';


type ProduktDTOEntityState = EntityState<ProduktDTO> & { ids: string[] };


export const produktEntitiesMock: ProduktDTOEntityState = {
  entities: {
    '1': {
      id: '1',
      inhaberId: '2',
      produktbezeichnung: 'Das ist ein Produkt A, das sehr gut ist.',
      produktnummer: 1001,
      produktbeschreibung: 'Dies ist Produkt A',
      berechnungsartId: produktBerechnungsartenMock[0].id,
      ustProzentsatzId: produktUstSaetzeMock[0].id,
      einheitId: produktEinheitenMock[0].einheitId,
      betragssumme: 500,
    },
    '2': {
      id: '2',
      inhaberId: '2',
      produktbezeichnung: 'Das Produkt B ist auch nicht schlecht.',
      produktnummer: 1002,
      produktbeschreibung: 'Dies ist Produkt B',
      berechnungsartId: produktBerechnungsartenMock[0].id,
      ustProzentsatzId: produktUstSaetzeMock[0].id,
      betragssumme: 1000,
    },
    '3': {
      id: '3',
      inhaberId: '2',
      produktbezeichnung: 'Von diesem Produkt C werden Sie begeistert sein.',
      produktnummer: 1003,
      produktbeschreibung: 'Dies ist Produkt C',
      berechnungsartId: produktBerechnungsartenMock[0].id,
      ustProzentsatzId: produktUstSaetzeMock[0].id,
      einheitId: produktEinheitenMock[0].einheitId,
      betragssumme: 1500,
    },
    '4': {
      id: '4',
      inhaberId: '2',
      produktbezeichnung: 'Produkt D ist aber auch nicht zu verachten.',
      produktnummer: 1004,
      produktbeschreibung: 'Dies ist Produkt D',
      berechnungsartId: produktBerechnungsartenMock[0].id,
      ustProzentsatzId: produktUstSaetzeMock[0].id,
      einheitId: produktEinheitenMock[0].einheitId,
      betragssumme: 234234,
    },
    '5': {
      id: '5',
      inhaberId: '2',
      produktbezeichnung: 'Von Produkt E sollten Sie allerdings die Finger lassen. Von Produkt E sollten Sie allerdings die Finger lassen.',
      produktnummer: 1005,
      produktbeschreibung: 'Dies ist Produkt E',
      berechnungsartId: produktBerechnungsartenMock[0].id,
      ustProzentsatzId: produktUstSaetzeMock[0].id,
      betragssumme: 892,
    },
    '6': {
      id: '6',
      inhaberId: '2',
      produktbezeichnung: 'Produkt F ist auch nicht schlecht.',
      produktnummer: 1006,
      produktbeschreibung: 'Dies ist Produkt F',
      berechnungsartId: produktBerechnungsartenMock[0].id,
      ustProzentsatzId: produktUstSaetzeMock[0].id,
      betragssumme: 234,
    },
    '7': {
      id: '7',
      inhaberId: '2',
      produktbezeichnung: 'Produkt G',
      produktnummer: 1007,
      produktbeschreibung: 'Dies ist Produkt G',
      berechnungsartId: produktBerechnungsartenMock[0].id,
      ustProzentsatzId: produktUstSaetzeMock[0].id,
      betragssumme: 89893,
    },
    '8': {
      id: '8',
      inhaberId: '2',
      produktbezeichnung: 'Produkt Ohne Preis',
      produktnummer: 1008,
      produktbeschreibung: 'Dies ist ein Produkt ohne Preis',
      berechnungsartId: produktBerechnungsartenMock[0].id,
      ustProzentsatzId: produktUstSaetzeMock[0].id,
    },
    '9': {
      id: '9',
      inhaberId: '2',
      produktbezeichnung: 'Produkt I',
      produktnummer: 1009,
      produktbeschreibung: 'Dies ist Produkt I',
      berechnungsartId: produktBerechnungsartenMock[0].id,
      ustProzentsatzId: produktUstSaetzeMock[0].id,
      betragssumme: 8792,
    },
    '10': {
      id: '10',
      inhaberId: '2',
      produktbezeichnung: 'Wie bitte? Nein ich bin garkein Produkt.',
      produktnummer: 1,
      produktbeschreibung: 'Bitte lassen sie mich raus, ich bin in diese Tabelle gefangen!',
      berechnungsartId: produktBerechnungsartenMock[0].id,
      betragssumme: 500,
      ustProzentsatzId: produktUstSaetzeMock[0].id,
      einheitId: produktEinheitenMock[0].einheitId,
    },
  },
  ids: [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
  ]
};
