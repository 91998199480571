import {EntityState} from '@ngrx/entity';
import {AbsenderDTO, AbsenderTypeDTO} from '../../openapi/fakturierung-openapi';


type AbsenderDTOEntityState = EntityState<AbsenderDTO> & { ids: string[] };

export const absenderEntitiesMock: AbsenderDTOEntityState = {
  entities: {
    '1': {
      id: '1',
      inhaberId: '2',
      kundennummer: 1234567890,
      anrede: 'Herr',
      nachname: 'Mustermann',
      vorname: 'Max',
      firma: 'Max Mustermann GmbH',
      bezeichnung: 'Max Mustermann GmbH',
      strasseundhausnummer: 'Musterstraße 10a',
      emailAdresse: 'max@mustermann.de',
      internetaddresse: 'www.mustermann.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017612345678',
      ort: 'Berlin',
      plz: '10115',
      steuernummer: '123/456/7890',
      telefonnummer: '0301234567',
      titel: 'Dr.',
      ustId: 'DE123456789',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '2': {
      id: '2',
      inhaberId: '2',
      kundennummer: 9876543210,
      anrede: 'Frau',
      nachname: 'Schmidt',
      vorname: 'Anna',
      firma: 'Anna Schmidt AG',
      bezeichnung: 'Anna Schmidt AG',
      emailAdresse: 'anna@schmidt.de',
      strasseundhausnummer: 'Griesbach Hill 1',
      internetaddresse: 'www.schmidt.de',
      land: {
        land: 'Kanada',
        landId: 'kanada',
      },
      mobilnummer: '017698765432',
      ort: 'Edmonton',
      plz: 'AB T5E 6V4',
      steuernummer: '321/654/9876',
      telefonnummer: '0891234567',
      titel: 'Prof.',
      ustId: 'DE987654321',
      type: AbsenderTypeDTO.Privatperson
    },
    '3': {
      id: '3',
      inhaberId: '2',
      kundennummer: 2345678901,
      anrede: 'Herr',
      nachname: 'Eisen',
      vorname: 'Björn',
      firma: 'Björn Eisen',
      bezeichnung: 'Björn Eisen',
      emailAdresse: 'bjoern@eisenindustrie.de',
      strasseundhausnummer: 'Inukshuk St 1',
      internetaddresse: 'www.eisenindustrie.de',
      land: {
        land: 'Kanada',
        landId: 'kanada',
      },
      mobilnummer: '01601234567',
      ort: 'Gjoa Haven',
      plz: 'NU X0B 1J0',
      steuernummer: '222/333/4444',
      telefonnummer: '0691234567',
      titel: 'Dipl.-Ing.',
      ustId: 'DE234567890',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '4': {
      id: '4',
      inhaberId: '2',
      kundennummer: 3456789012,
      anrede: 'Herr',
      nachname: 'Zeiss',
      vorname: 'Carl',
      firma: 'Carl Zeiss AG',
      bezeichnung: 'Carl Zeiss AG',
      emailAdresse: 'info@zeiss.de',
      strasseundhausnummer: 'Carl-Zeiss-Straße 1',
      internetaddresse: 'www.zeiss.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '015745678942',
      ort: 'Jena',
      plz: '07743',
      steuernummer: '950/750/3005',
      telefonnummer: '036416770',
      titel: 'Dr.',
      ustId: 'DE998877665',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '5': {
      id: '5',
      inhaberId: '2',
      kundennummer: 4567890123,
      anrede: 'Frau',
      nachname: 'Weber',
      vorname: 'Julia',
      firma: 'Julia Weber GmbH',
      bezeichnung: 'Julia Weber GmbH',
      emailAdresse: 'julia@weber.com',
      strasseundhausnummer: 'Königstraße 1',
      internetaddresse: 'www.weber.com',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017745679812',
      ort: 'Stuttgart',
      plz: '70174',
      steuernummer: '755/853/9924',
      telefonnummer: '07112345678',
      titel: 'M.Sc.',
      ustId: 'DE654321876',
      type: AbsenderTypeDTO.Privatperson
    },
    '6': {
      id: '6',
      inhaberId: '2',
      kundennummer: 5678901234,
      anrede: 'Herr',
      nachname: 'Bauer',
      vorname: 'Friedrich',
      firma: 'Friedrich Bauer KG',
      bezeichnung: 'Friedrich Bauer KG',
      emailAdresse: 'friedrich@bauerk.de',
      strasseundhausnummer: 'Königstraße 1',
      internetaddresse: 'www.bauerk.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '016212345678',
      ort: 'Stuttgart',
      plz: '70173',
      steuernummer: '265/593/5813',
      telefonnummer: '07111234567',
      titel: 'MBA',
      ustId: 'DE101212101',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '7': {
      id: '7',
      inhaberId: '2',
      kundennummer: 6789012345,
      anrede: 'Frau',
      nachname: 'Schultz',
      vorname: 'Lena',
      firma: 'Lena Schultz GmbH',
      bezeichnung: 'Lena Schultz GmbH',
      emailAdresse: 'lena@schultz.de',
      strasseundhausnummer: 'Augustiweg 89',
      internetaddresse: 'www.schultz.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '01749999999',
      ort: 'Berlin',
      plz: '10117',
      steuernummer: '801/501/25914',
      telefonnummer: '0309876543',
      titel: 'M.A.',
      ustId: 'DE135792468',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '8': {
      id: '8',
      inhaberId: '2',
      kundennummer: 7890123456,
      anrede: 'Frau',
      nachname: 'Heinz',
      vorname: 'Isabella',
      firma: 'Isabella Heinz',
      bezeichnung: 'Isabella Heinz',
      emailAdresse: 'contact@heinz-cosmetics.com',
      strasseundhausnummer: 'Königstraße 19',
      internetaddresse: 'www.heinz-cosmetics.com',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '01701010101',
      ort: 'Hamburg',
      plz: '20457',
      steuernummer: '869/748/95468',
      telefonnummer: '0401234567',
      titel: 'B.Sc.',
      ustId: 'DE201020304',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '9': {
      id: '9',
      inhaberId: '2',
      kundennummer: 8901234567,
      anrede: 'Herr',
      nachname: 'Kern',
      vorname: 'Otto',
      firma: 'Otto Kern',
      bezeichnung: 'Otto Kern',
      emailAdresse: 'otto@kern.de',
      strasseundhausnummer: 'Königstraße 1',
      internetaddresse: 'www.kern.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '015201020304',
      ort: 'Köln',
      plz: '50667',
      steuernummer: '760/856/9087',
      telefonnummer: '02211234567',
      titel: 'Prof. Dr.',
      ustId: 'DE909876543',
      type: AbsenderTypeDTO.Privatperson
    },
    '10': {
      id: '10',
      inhaberId: '2',
      kundennummer: 9012345678,
      anrede: 'Herr',
      nachname: 'Leitner',
      vorname: 'George',
      firma: 'George Leitner',
      bezeichnung: 'George Leitner',
      emailAdresse: 'george@leitner-modular.com',
      strasseundhausnummer: 'Albrechtalleé 2',
      internetaddresse: 'www.leitner-modular.com',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '01601234567',
      ort: 'Leipzig',
      plz: '04109',
      steuernummer: '690/777/55578',
      telefonnummer: '03411234567',
      titel: 'M.Eng.',
      ustId: 'DE101112131',
      type: AbsenderTypeDTO.Privatperson
    },
    '11': {
      id: '11',
      inhaberId: '2',
      kundennummer: 1122334455,
      anrede: 'Frau',
      nachname: 'Curie',
      vorname: 'Marie',
      firma: 'Marie Curie',
      bezeichnung: 'Marie Curie',
      emailAdresse: 'marie@curielab.com',
      strasseundhausnummer: 'Bingbongstr. 4',
      internetaddresse: 'www.curielab.com',
      land:
        {
          land: 'Frankreich',
          landId: 'frankreich',
        },
      mobilnummer: '030123456789',
      ort: 'Paris',
      plz: '75001',
      steuernummer: '790/888/6664',
      telefonnummer: '0121234567',
      titel: 'Dr.',
      ustId: 'FR123456789',
      type: AbsenderTypeDTO.Privatperson
    },
    '12': {
      id: '12',
      inhaberId: '2',
      kundennummer: 2233445566,
      anrede: 'Herr',
      nachname: 'Müller',
      vorname: 'Peter',
      firma: 'Peter Müller KG',
      bezeichnung: 'Peter Müller KG',
      emailAdresse: 'info@muellerkg.de',
      strasseundhausnummer: 'Königstraße 1',
      internetaddresse: 'www.muellerkg.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '015025025025',
      ort: 'Dresden',
      plz: '01067',
      steuernummer: '490/490/00494',
      telefonnummer: '0351203040',
      titel: 'Dr.',
      ustId: 'DE750951753',
      type: AbsenderTypeDTO.Privatperson
    },
    '13': {
      id: '13',
      inhaberId: '2',
      kundennummer: 3344556677,
      anrede: 'Frau',
      nachname: 'Hermann',
      vorname: 'Elisa',
      strasseundhausnummer: 'Königstraße 1',
      firma: 'Elisa Hermann Solutions',
      bezeichnung: 'Elisa Hermann',
      emailAdresse: 'elisa@hermannsolutions.org',
      internetaddresse: 'www.hermannsolutions.org',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '015790123456',
      ort: 'Dresden',
      plz: '01069',
      steuernummer: '245/999/2034',
      telefonnummer: '0351748392',
      titel: 'PhD',
      ustId: 'DE999888777',
      type: AbsenderTypeDTO.Privatperson
    },
    '14': {
      id: '14',
      inhaberId: '2',
      kundennummer: 4455667788,
      anrede: 'Herr',
      nachname: 'Schwarz',
      strasseundhausnummer: 'Königstraße s1',
      vorname: 'Tobias',
      firma: 'Tobias Schwarz GmbH',
      bezeichnung: 'Tobias Schwarz GmbH',
      emailAdresse: 'info@schwarzgmbh.de',
      internetaddresse: 'www.schwarzgmbh.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017112233445',
      ort: 'Hamburg',
      plz: '20459',
      steuernummer: '214/081/3344',
      telefonnummer: '0401234567',
      titel: 'Dipl.-Kfm.',
      ustId: 'DE123455432',
      type: AbsenderTypeDTO.Privatperson
    },
    '15': {
      id: '15',
      inhaberId: '2',
      kundennummer: 5566778899,
      anrede: 'Frau',
      nachname: 'Neuhaus',
      vorname: 'Laura',
      firma: 'Laura Neuhaus AG',
      bezeichnung: 'Laura Neuhaus AG',
      emailAdresse: 'laura@neuhausag.de',
      strasseundhausnummer: 'Königstraße 1',
      internetaddresse: 'www.neuhausag.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017612345685',
      ort: 'Kiel',
      plz: '24103',
      steuernummer: '304/556/7842',
      telefonnummer: '0431123456',
      titel: 'Dr.',
      ustId: 'DE231456789',
      type: AbsenderTypeDTO.Privatperson
    },
    '16': {
      id: '16',
      inhaberId: '2',
      kundennummer: 6677889900,
      anrede: 'Herr',
      nachname: 'Weiß',
      vorname: 'Michael',
      firma: 'Michael Weiß Zwirnerei',
      bezeichnung: 'Michael Weiß Zwirnerei',
      emailAdresse: 'm.weiss@zwirnereiweiss.de',
      internetaddresse: 'www.zwirnereiweiss.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017512345670',
      ort: 'Lübeck',
      plz: '23552',
      steuernummer: '223/554/3219',
      strasseundhausnummer: 'Königstraße 1',
      telefonnummer: '0451123456',
      titel: 'Prof. Dr.',
      ustId: 'DE987654321',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '17': {
      id: '17',
      inhaberId: '2',
      kundennummer: 7788990011,
      anrede: 'Frau',
      nachname: 'Felder',
      vorname: 'Susanne',
      firma: 'Susanne Felder KG',
      bezeichnung: 'Susanne Felder KG',
      emailAdresse: 's.felder@felderkg.de',
      strasseundhausnummer: 'Hubertstr. 1',
      internetaddresse: 'www.felderkg.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017612345619',
      ort: 'Rostock',
      plz: '18055',
      steuernummer: '336/079/4336',
      telefonnummer: '0381123456',
      titel: 'PhD',
      ustId: 'DE876543210',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '18': {
      id: '18',
      inhaberId: '2',
      kundennummer: 8899001122,
      anrede: 'Herr',
      nachname: 'Klein',
      strasseundhausnummer: 'Kaiserstraße 1222f',
      vorname: 'Hans',
      firma: 'Hans Klein Media',
      bezeichnung: 'Hans Klein Media',
      emailAdresse: 'info@kleinmedia.de',
      internetaddresse: 'www.kleinmedia.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017632541478',
      ort: 'Leipzig',
      plz: '04109',
      steuernummer: '099/565/7893',
      telefonnummer: '03414681011',
      titel: 'Dr.',
      ustId: 'DE102938475',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '19': {
      id: '19',
      inhaberId: '2',
      kundennummer: 9900112233,
      anrede: 'Frau',
      nachname: 'Stein',
      vorname: 'Emma',
      firma: 'Emma Stein Bau GmbH',
      bezeichnung: 'Emma Stein Bau GmbH',
      emailAdresse: 'e.stein@steinbau.de',
      strasseundhausnummer: 'Kaiserstraße 1',
      internetaddresse: 'www.steinbau.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017611223344',
      ort: 'Dortmund',
      plz: '44135',
      steuernummer: '750/030/1122',
      telefonnummer: '02311234567',
      titel: 'Dipl.-Ing.',
      ustId: 'DE123456780',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '20': {
      id: '20',
      inhaberId: '2',
      kundennummer: 1122334455,
      anrede: 'Herr',
      nachname: 'Jung',
      vorname: 'Felix',
      firma: 'Felix Jung GmbH',
      bezeichnung: 'Felix Jung GmbH',
      emailAdresse: 'f.jung@junggmbh.de',
      strasseundhausnummer: 'Königstraße 1',
      internetaddresse: 'www.junggmbh.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017612345690',
      ort: 'Essen',
      plz: '45127',
      steuernummer: '123/456/78910',
      telefonnummer: '0201123456',
      titel: 'Prof.',
      ustId: 'DE109876543',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '21': {
      id: '21',
      inhaberId: '2',
      kundennummer: 2233445566,
      anrede: 'Herr',
      nachname: 'Schwarz',
      vorname: 'Oliver',
      strasseundhausnummer: 'Am Wall 1',
      firma: 'Oliver Schwarz IT-Solutions',
      bezeichnung: 'Oliver Schwarz IT-Solutions',
      emailAdresse: 'o.schwarz@itsolutions.de',
      internetaddresse: 'www.itsolutions.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '01761239988',
      ort: 'Bremen',
      plz: '28195',
      steuernummer: '132/465/7987',
      telefonnummer: '0421123456',
      titel: 'MSc',
      ustId: 'DE2143658709',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '22': {
      id: '22',
      inhaberId: '2',
      kundennummer: 3344556677,
      anrede: 'Frau',
      nachname: 'Groß',
      vorname: 'Melanie',
      firma: 'Melanie Groß Textilien',
      bezeichnung: 'Melanie Groß Textilien',
      emailAdresse: 'contact@grosstext.de',
      strasseundhausnummer: 'Kaiserstraße 32',
      internetaddresse: 'www.grosstext.de',
      land:
        {
          land: 'Deutschland',
          landId: 'deutschland',
        },
      mobilnummer: '017640120987',
      ort: 'Wiesbaden',
      plz: '65183',
      steuernummer: '208/276/0538',
      telefonnummer: '0611123456',
      titel: 'Dipl.-Bw.',
      ustId: 'DE987654320',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '23': {
      id: '23',
      inhaberId: '2',
      kundennummer: 4455667788,
      anrede: 'Herr',
      nachname: 'Vogel',
      vorname: 'Andreas',
      firma: 'Andreas Vogel Consulting',
      bezeichnung: 'Andreas Vogel Consulting',
      emailAdresse: 'a.vogel@vogelconsulting.de',
      strasseundhausnummer: 'Bismarckstraße 1',
      internetaddresse: 'www.vogelconsulting.de',
      land:
        {
          land: 'Deutschland',
          landId: 'deutschland',
        },
      mobilnummer: '017612345601',
      ort: 'Heidelberg',
      plz: '69117',
      steuernummer: '360/746/2880',
      telefonnummer: '06221123456',
      titel: 'Dr.',
      ustId: 'DE192837465',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '24': {
      id: '24',
      inhaberId: '2',
      kundennummer: 5566778899,
      anrede: 'Frau',
      nachname: 'Sonne',
      vorname: 'Victoria',
      firma: 'Victoria Sonne GmbH',
      bezeichnung: 'Victoria Sonne GmbH',
      emailAdresse: 'v.sonne@sonnegmbh.de',
      internetaddresse: 'www.sonnegmbh.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017644412212',
      ort: 'Freiburg',
      plz: '79098',
      steuernummer: '220/990/4034',
      strasseundhausnummer: 'Kaiser-Joseph-Straße 1',
      telefonnummer: '0761123456',
      titel: 'PhD',
      ustId: 'DE198765432',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '25': {
      id: '25',
      inhaberId: '2',
      kundennummer: 6677889900,
      anrede: 'Herr',
      nachname: 'Meyer',
      vorname: 'Frederik',
      firma: 'Frederik Meyer Logistik',
      bezeichnung: 'Frederik Meyer Logistik',
      emailAdresse: 'f.meyer@meyerlogistik.de',
      strasseundhausnummer: 'Königstraße 3',
      internetaddresse: 'www.meyerlogistik.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017677889900',
      ort: 'Nürnberg',
      plz: '90402',
      steuernummer: '241/674/5543',
      telefonnummer: '0911123456',
      titel: 'Dr.',
      ustId: 'DE208765431',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '26': {
      id: '26',
      inhaberId: '2',
      kundennummer: 7788990011,
      anrede: 'Frau',
      nachname: 'Neumann',
      vorname: 'Isabelle',
      firma: 'Isabelle Neumann New Media',
      bezeichnung: 'Isabelle Neumann New Media',
      emailAdresse: 'isabelle@neumannmedia.de',
      strasseundhausnummer: 'Münchner Straße 1',
      internetaddresse: 'www.neumannmedia.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017699887766',
      ort: 'München',
      plz: '80333',
      steuernummer: '342/766/4880',
      telefonnummer: '0891234567',
      titel: 'M.A.',
      ustId: 'DE123456788',
      type: AbsenderTypeDTO.Geschaeftskunde
    },
    '27': {
      id: '27',
      inhaberId: '2',
      kundennummer: 8899001122,
      anrede: 'Herr',
      nachname: 'Sommer',
      vorname: 'Theo',
      firma: 'Theo Sommer Elektronik GmbH',
      bezeichnung: 'Theo Sommer Elektronik GmbH',
      emailAdresse: 'theo@sommer-elektronik.de',
      internetaddresse: 'www.sommer-elektronik.de',
      strasseundhausnummer: 'Königsallee 1',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017655544332',
      ort: 'Düsseldorf',
      plz: '40215',
      steuernummer: '150/750/4590',
      telefonnummer: '02111234678',
      titel: 'Dipl.-Ing.',
      ustId: 'DE304957286',
      type: AbsenderTypeDTO.Privatperson
    },
    '28': {
      id: '28',
      inhaberId: '2',
      kundennummer: 9900112233,
      anrede: 'Frau',
      nachname: 'Dietrich',
      vorname: 'Helena',
      firma: 'Helena Dietrich Design',
      bezeichnung: 'Helena Dietrich Design',
      emailAdresse: 'info@dietrichdesign.de',
      strasseundhausnummer: 'Hildesheimer Straße 1',
      internetaddresse: 'www.dietrichdesign.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '017688855544',
      ort: 'Hannover',
      plz: '30159',
      steuernummer: '301/483/9098',
      telefonnummer: '05111234567',
      titel: 'M.F.A.',
      ustId: 'DE987654321',
      type: AbsenderTypeDTO.Privatperson
    },
    '29': {
      id: '29',
      inhaberId: '2',
      kundennummer: 1122334455,
      anrede: 'Frau',
      nachname: 'Lorenz',
      vorname: 'Sophia',
      firma: 'Sophia Lorenz AG',
      bezeichnung: 'Sophia Lorenz AG',
      emailAdresse: 'sophia@lorenz.de',
      strasseundhausnummer: 'Königsallee 1',
      internetaddresse: 'www.lorenz.de',
      land: {
        land: 'Deutschland',
        landId: 'deutschland',
      },
      mobilnummer: '01761239876',
      ort: 'Düsseldorf',
      plz: '40213',
      steuernummer: '987/654/3210',
      telefonnummer: '02111234567',
      titel: 'MBA',
      ustId: 'DE876543219',
      type: AbsenderTypeDTO.Privatperson
    },
    '30': {
      id: '30',
      inhaberId: '2',
      kundennummer: 2233445566,
      anrede: 'Herr',
      nachname: 'Nowak',
      vorname: 'Jan',
      firma: 'Jan Nowak',
      bezeichnung: 'Jan Nowak',
      emailAdresse: 'jan@nowak.pl',
      internetaddresse: 'www.nowak.pl',
      land: {
        land: 'Polen',
        landId: 'polen',
      },
      mobilnummer: '048612345678',
      ort: 'Warschau',
      plz: '00-001',
      steuernummer: '143/555/7894',
      strasseundhausnummer: 'Nowakstraße 1',
      telefonnummer: '0221234567',
      titel: 'PhD',
      ustId: 'PL123456789',
      type: AbsenderTypeDTO.Privatperson
    },
  },
  ids: [
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
    '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
    '21', '22', '23', '24', '25', '26', '27', '28', '29', '30',
  ]
};
