import {Component, Input} from '@angular/core';
import {
  ButtonModule,
  FormFieldNumberComponent,
  FormFieldSelectComponent,
  FormFieldTextComponent, OptionComponent
} from '@adnova/jf-ng-components';
import {NgIf} from '@angular/common';
import {ProduktFormControls} from './produkt-form-controls.interface';


@Component({
  selector: 'app-produkt-form',
  standalone: true,
  imports: [
    ButtonModule,
    FormFieldNumberComponent,
    FormFieldSelectComponent,
    FormFieldTextComponent,
    NgIf,
  ],
  templateUrl: './produkt-form.component.html',
  styleUrls: ['./produkt-form.component.scss']
})
export class ProduktFormComponent {
  @Input() public formControls?: ProduktFormControls;
  @Input() public einheitenOptions?: OptionComponent[];
  @Input() public berechnungsarten?: OptionComponent[];
  @Input() public umsatzsteuersaetze?: OptionComponent[];
}
