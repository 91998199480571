import {
  BerechnungsartDTO,
  BerechnungsartDTOBerechnungsartEnum,
  EinheitDTO,
  EinheitDTOMengenartEnum,
  UstProzentsatzDTO
} from '../../openapi/fakturierung-openapi';


export const produktEinheitenMock: EinheitDTO[] = [
  {
    einheitId: '1',
    nummer: 11,
    kurzbezeichnung: 'Stk.',
    bezeichnung: 'Stück',
    mengenart: EinheitDTOMengenartEnum.Stueckmenge,
  },
  {
    einheitId: '2',
    nummer: 12,
    kurzbezeichnung: 'kg',
    bezeichnung: 'Kilogramm',
    mengenart: EinheitDTOMengenartEnum.Gewichtsmenge,
  },
  {
    einheitId: '3',
    nummer: 13,
    kurzbezeichnung: 'l',
    bezeichnung: 'Liter',
    mengenart: EinheitDTOMengenartEnum.Gewichtsmenge,
  },
  {
    einheitId: '4',
    nummer: 14,
    kurzbezeichnung: 'sqrm',
    bezeichnung: 'Quadratmeter',
    mengenart: EinheitDTOMengenartEnum.Stueckmenge,
  },
  {
    einheitId: '5',
    nummer: 15,
    kurzbezeichnung: 'm',
    bezeichnung: 'Meter',
    mengenart: EinheitDTOMengenartEnum.Stueckmenge,
  }
];

export const produktUstSaetzeMock: UstProzentsatzDTO[] = [
  {
    id: '19',
    prozentsatz: 0.19,
  },
  {
    id: '7',
    prozentsatz: 0.07,
  },
  {
    id: '9',
    prozentsatz: 0.09,
  },
  {
    id: '5.5',
    prozentsatz: 0.055,
  },
  {
    id: '0.00',
    prozentsatz: 0.00,
  }
];

export const produktBerechnungsartenMock: BerechnungsartDTO[] = [
  {
    id: 'NETTO',
    berechnungsart: BerechnungsartDTOBerechnungsartEnum.Netto,
  },
  {
    id: 'BRUTTO',
    berechnungsart: BerechnungsartDTOBerechnungsartEnum.Brutto,
  },
];
