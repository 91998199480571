import {FakturierungsbelegFormState} from '../states/fakturierungsbeleg-form.state';
import {createReducer, on} from '@ngrx/store';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';


export const initialFakturierungsbelegFormState: FakturierungsbelegFormState = {};

export const fakturierungsbelegFormReducer = createReducer(
  initialFakturierungsbelegFormState,

  on(
    FakturierungsbelegFormActions.selectLeistungsempfaenger,
    (state, {absenderId}) => {
      return {
        ...state,
        absenderId,
      };
    }
  ),
);
