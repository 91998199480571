import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';
import {FormInput} from '../../components/form/form-input.interface';
import {Subject} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CreateAbsenderFormControls} from './create-absender-form.controls';
import {
  RadioButton
} from '@adnova/jf-ng-components/lib/generic/form-elements/form-fields/radio/radio-button/radio-button.interface';
import {CreateAbsenderDialogActions} from '../../../store/actions/create-absender-dialog.actions';
import {
  AbsenderPrivatpersonFormControls
} from '../../components/forms/absender-privatperson-form/absender-privatperson-form.controls';
import {
  AbsenderGeschaeftskundeFormControls
} from '../../components/forms/absender-geschaeftskunde-form/absender-geschaeftskunde-form.interface';
import {map, takeUntil} from 'rxjs/operators';
import {CreateAbsenderDialogSelectors} from '../../../store/selectors/create-absender-dialog.selectors';
import {AbsenderEntitiesActions} from '../../../store/actions/absender-entities.actions';
import {
  CreateAbsenderGeschaeftskundeRequestDTO,
  CreateAbsenderPrivatpersonRequestDTO
} from '../../../openapi/fakturierung-openapi';
import {FormFieldSelectValue, InhaberEntitiesSelectors, OptionComponent} from '@adnova/jf-ng-components';
import {AbsenderEntitiesSelectors} from '../../../store/selectors/absender-entities.selectors';
import {AnredenDialogData} from '../../../interfaces/anreden-data.interface';


@Component({
  selector: 'app-create-produkt-dialog',
  templateUrl: './create-absender-dialog.component.html',
  styleUrls: ['./create-absender-dialog.component.scss']
})
export class CreateAbsenderDialogComponent implements OnInit, OnDestroy, AfterViewInit {

  private readonly unsubscribe$ = new Subject<void>();

  private inhaberId?: string;
  private anredenData: AnredenDialogData[] = [
    {
      label: 'Frau',
      isSelected: false,
    },
    {
      label: 'Herr',
      isSelected: false,
    },
    {
      label: 'Nicht zutreffend',
      isSelected: false,
    },
  ];

  public _laenderGeschaeft: OptionComponent[] = [];
  private _laenderPrivat: OptionComponent[] = [];
  private _anreden: OptionComponent[] = [];

  protected readonly absenderPrivat = 'privat';
  protected readonly absenderGeschaeft = 'geschaeft';
  protected nextKundennummer?: number;

  protected formControlsPrivat: AbsenderPrivatpersonFormControls = {
    anrede: new FormControl({}),
    titel: new FormControl(''),
    kundennummer: new FormControl(null, [Validators.required]),
    vorname: new FormControl('', [Validators.required]),
    nachname: new FormControl('', [Validators.required]),
    strasseundhausnummer: new FormControl('', [Validators.required]),
    plz: new FormControl('', [Validators.required]),
    ort: new FormControl('', [Validators.required]),
    landPrivat: new FormControl({}, [Validators.required]),
    email: new FormControl('', [Validators.email]),
  };

  protected formControlsGeschaeft: AbsenderGeschaeftskundeFormControls = {
    firma: new FormControl(''),
    kundennummer: new FormControl(null, [Validators.required]),
    strasseundhausnummer: new FormControl('', [Validators.required]),
    plz: new FormControl('', [Validators.required]),
    ort: new FormControl('', [Validators.required]),
    landGeschaeft: new FormControl({}, [Validators.required]),
    ustId: new FormControl(''),
    email: new FormControl('', [Validators.email]),
    ansprechpartnerAnrede: new FormControl({}),
    ansprechpartnerTitel: new FormControl(''),
    ansprechpartnerVorname: new FormControl(''),
    ansprechpartnerNachname: new FormControl(''),
  };

  protected absenderTypes: RadioButton[] = [
    {
      label: 'Geschäftskunde',
      value: this.absenderGeschaeft,
      isSelected: true,
    },
    {
      label: 'Privatkunde',
      value: this.absenderPrivat,
    },
  ];

  protected formInputs: FormInput = {
    title: 'Neuen Kontakt anlegen',
    cancelButtonLabel: 'Abbrechen',
  };

  public formControls: CreateAbsenderFormControls = {
    absenderType: new FormControl(null),
    privat: new FormGroup(this.formControlsPrivat),
    geschaeft: new FormGroup(this.formControlsGeschaeft),
  };

  public formGroup = new FormGroup(this.formControls);

  public selectedAbsenderType = this.absenderTypes.find(type => type.isSelected)?.value;

  get laenderGeschaeft(): OptionComponent[] {
    return this._laenderGeschaeft;
  }

  get laenderPrivat(): OptionComponent[] {
    return this._laenderPrivat;
  }

  get anreden(): OptionComponent[] {
    return this._anreden;
  }

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    for (const anrede of this.anredenData) {
      const anredeOption = new OptionComponent();
      anredeOption.id = anrede.label;
      anredeOption.label = anrede.label;
      anredeOption.isSelected = anrede.isSelected;

      this._anreden.push(anredeOption);
    }

    // INFO: Inhaber ID
    this.store.select(InhaberEntitiesSelectors.currentInhaber).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaber => {
      this.inhaberId = inhaber?.id;
    });

    // INFO: Next Kundennummer
    this.store.select(AbsenderEntitiesSelectors.nextKundennummer).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(kundennummer => {
      this.formControls.privat.get('kundennummer')?.setValue(kundennummer);
      this.formControls.geschaeft.get('kundennummer')?.setValue(kundennummer);
    });

    // INFO: Laender
    this.store.select(CreateAbsenderDialogSelectors.laender).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(laender => {
      if (!laender) return;

      for (const land of laender) {
        const option = new OptionComponent();
        option.id = land.landId;
        option.label = land.land || '';
        option.isSelected = false;

        this._laenderPrivat.push(option);
        this._laenderGeschaeft.push(option);
      }
    });

    this.formControls.absenderType.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      if (!value) return;
      this.selectedAbsenderType = value.value;
    });
  }

  ngAfterViewInit(): void {
    this.formControls.geschaeft.get('landGeschaeft')?.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      map(value => value as FormFieldSelectValue),
    ).subscribe(value => {
      const id = value.selectedOptionValueIds?.[0];
      const selectedOption = this.laenderGeschaeft.find(option => {
        return option.id === id;
      });
      const ustIdFormField = this.formControls.geschaeft.get('ustId');

      // FIXME: Sobald die API die Id des Landes Deutschland bereitstellt muss auf die korrekte Id verglichen werden.
      if (selectedOption?.id === '1') {
        ustIdFormField?.setValidators(null);
      } else {
        ustIdFormField?.setValidators([Validators.required]);
      }
      ustIdFormField?.updateValueAndValidity();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected createClick(): void {
    const absenderType = this.formControls.absenderType.value?.value;
    if (absenderType === this.absenderPrivat) {
      this.store.dispatch(AbsenderEntitiesActions.createAbsenderPrivatperson({
        absenderDto: this.createPrivatpersonDTO(),
        inhaberId: this.inhaberId!,
      }));
    } else if (absenderType === this.absenderGeschaeft) {
      this.store.dispatch(AbsenderEntitiesActions.createAbsenderGeschaeftskunde({
        absenderDto: this.createGeschaeftskundeDTO(),
        inhaberId: this.inhaberId!,
      }));
    }
  }

  private createPrivatpersonDTO(): CreateAbsenderPrivatpersonRequestDTO {
    return {
      anrede: this.formControls.privat.get('anrede')?.value,
      ort: this.formControls.privat.get('ort')?.value,
      kundennummer: this.formControls.privat.get('kundennummer')?.value,
      strasseundhausnummer: this.formControls.privat.get('strasseundhausnummer')?.value,
      land: this.formControls.privat.get('landPrivat')?.value,
      nachname: this.formControls.privat.get('nachname')?.value,
      plz: this.formControls.privat.get('plz')?.value,
      titel: this.formControls.privat.get('titel')?.value,
      vorname: this.formControls.privat.get('vorname')?.value,
      emailAdresse: this.formControls.privat.get('email')?.value,
    };
  }

  private createGeschaeftskundeDTO(): CreateAbsenderGeschaeftskundeRequestDTO {
    return {
      firma: this.formControls.geschaeft.get('firma')?.value,
      ort: this.formControls.geschaeft.get('ort')?.value,
      kundennummer: this.formControls.geschaeft.get('kundennummer')?.value,
      strasseundhausnummer: this.formControls.geschaeft.get('strasseundhausnummer')?.value,
      plz: this.formControls.geschaeft.get('plz')?.value,
      land: this.formControls.geschaeft.get('landGeschaeft')?.value,
      ustId: this.formControls.geschaeft.get('ustId')?.value,
      emailAdresse: this.formControls.geschaeft.get('email')?.value,
      ansprechpartner: {
        anrede: this.formControls.geschaeft.get('ansprechpartnerAnrede')?.value,
        nachname: this.formControls.geschaeft.get('ansprechpartnerNachname')?.value,
        titel: this.formControls.geschaeft.get('ansprechpartnerTitel')?.value,
        vorname: this.formControls.geschaeft.get('ansprechpartnerVorname')?.value,
      }
    };
  }

  protected closeDialogClick(): void {
    this.store.dispatch(CreateAbsenderDialogActions.close());
  }

  protected checkFormComplete(): boolean {
    return (this.formControls.absenderType.value?.value === this.absenderPrivat && this.formGroup.controls.privat.valid)
      || (this.formControls.absenderType.value?.value === this.absenderGeschaeft && this.formGroup.controls.geschaeft.valid);
  }
}
