import {AbsenderLeistungsempfaengerState} from '../states/absender-leistungsempfaenger.state';
import {createReducer, on} from '@ngrx/store';
import {AbsenderLeistungsempfaengerActions} from '../actions/absender-leistungsempfaenger.actions';
import {absenderEntitiesMock} from './absender-entities.mock';


export const initialAbsenderLeistungsempfaengerState: AbsenderLeistungsempfaengerState = {
  displayedIds: absenderEntitiesMock.ids,
  pageableDto: {
    limit: 15,
    offset: 0,
    sorting: [],
  },
  filterDto: {}
};

export const absenderLeistungsempfaengerReducer = createReducer(
  initialAbsenderLeistungsempfaengerState,

  on(
    AbsenderLeistungsempfaengerActions.setDisplayedIds,
    (state, {absenderDtos}) => ({
      ...state,
      displayedIds: absenderDtos.map(absender => absender.id!),
    }),
  ),

  on(
    AbsenderLeistungsempfaengerActions.clearDisplayedAbsender,
    (state) => ({
      ...state,
      displayedIds: [],
    }),
  ),

  on(
    AbsenderLeistungsempfaengerActions.updateTextFilter,
    (state, {textfilter}) => ({
      ...state,
      filterDto: {
        ...state.filterDto,
        textfilter,
      }
    }),
  ),

  on(
    AbsenderLeistungsempfaengerActions.loadMoreOptions,
    (state) => ({
      ...state,
      pageableDto: {
        ...state.pageableDto,
        offset: state.pageableDto.offset! + state.pageableDto.limit!,
      }
    }),
  ),
);
