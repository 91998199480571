import {createAction, props} from '@ngrx/store';
import {CreateAbsenderPrivatpersonRequestDTO, LandDTO} from '../../openapi/fakturierung-openapi';


export class CreateAbsenderDialogActions {

  public static open = createAction(
    '[Create Kontakt Dialog] Open create kontakt dialog.',
  );

  public static close = createAction(
    '[Create Kontakt Dialog] Close create kontakt dialog.',
  );

  public static readLaender = createAction(
    '[Create Kontakt Dialog] Read laender.',
  );

  public static readLaenderSuccess = createAction(
    '[Create Kontakt Dialog] Read laender success.',
    props<{laender: LandDTO[]}>(),
  );

  public static readLaenderFailure = createAction(
    '[Create Kontakt Dialog] Read laender failure.',
    props<{error: any}>(),
  );
}
