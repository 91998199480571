import {AbsenderEntitiesState} from '../states/absender-entities.state';
import {absenderEntitiesAdapter} from '../adapters/absender-entities.adapter';
import {createReducer, on} from '@ngrx/store';
import {absenderEntitiesMock} from './absender-entities.mock';
import {AbsenderEntitiesActions} from '../actions/absender-entities.actions';


export const initialAbsenderEntitiesState: AbsenderEntitiesState = absenderEntitiesAdapter.getInitialState({
  isLoading: false,
  entities: absenderEntitiesMock.entities,
  ids: absenderEntitiesMock.ids,
  count: absenderEntitiesMock.ids.length,
  nextKundennummer: 12345678, // FIXME: Mock entfernen
});

export const absenderEntitiesReducer = createReducer(
  initialAbsenderEntitiesState,

  on(
    AbsenderEntitiesActions.readNextKundennummerSuccess,
    (state, action) => ({
      ...state,
      nextKundennummer: action.nextKundennummer,
    })
  )
);
