import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  FormFieldNumberComponent,
  FormFieldSelectComponent,
  FormFieldTextComponent,
  OptionComponent
} from '@adnova/jf-ng-components';
import {AbsenderPrivatpersonFormControls} from './absender-privatperson-form.controls';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDividerModule} from '@angular/material/divider';


@Component({
  selector: 'app-absender-privatperson-form',
  standalone: true,
    imports: [CommonModule, FormFieldSelectComponent, FormFieldTextComponent, FormFieldNumberComponent, FormsModule, ReactiveFormsModule, MatDividerModule],
  templateUrl: './absender-privatperson-form.component.html',
  styleUrls: ['./absender-privatperson-form.component.scss']
})
export class AbsenderPrivatpersonFormComponent {

  @Input() public formControls?: AbsenderPrivatpersonFormControls;
  @Input() public laenderOptions?: OptionComponent[];
  @Input() public anredenOptions?: OptionComponent[];
}
