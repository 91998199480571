import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AbsenderGeschaeftskundeFormControls} from './absender-geschaeftskunde-form.interface';
import {
  FormFieldNumberComponent,
  FormFieldSelectComponent,
  FormFieldTextComponent,
  OptionComponent
} from '@adnova/jf-ng-components';
import {MatDividerModule} from '@angular/material/divider';
import {ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'app-absender-geschaeftskunde-form',
  standalone: true,
  imports: [CommonModule, FormFieldTextComponent, FormFieldNumberComponent, FormFieldSelectComponent, MatDividerModule, ReactiveFormsModule],
  templateUrl: './absender-geschaeftskunde-form.component.html',
  styleUrls: ['./absender-geschaeftskunde-form.component.scss']
})
export class AbsenderGeschaeftskundeFormComponent {

  @Input() public formControls?: AbsenderGeschaeftskundeFormControls;
  @Input() public laenderOptions?: OptionComponent[];
  @Input() public anredenOptions?: OptionComponent[];

  constructor() {
  }

}
