import {createAction, props} from '@ngrx/store';
import {
  AbsenderDTO,
  CreateAbsenderGeschaeftskundeRequestDTO,
  CreateAbsenderPrivatpersonRequestDTO
} from '../../openapi/fakturierung-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class AbsenderEntitiesActions {

  public static readAbsenderSuccess = createAction(
    '[Absender Entities] Read list of absenders successfully.',
    props<{
      absenderDtos: AbsenderDTO[]
    }>(),
  );

  public static readAbsenderFailed = createAction(
    '[Absender Entities] Read list of absenders failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static createAbsenderPrivatperson = createAction(
    '[Absender Entities] Create absender privatperson.',
    props<{
      inhaberId: string,
      absenderDto: CreateAbsenderPrivatpersonRequestDTO,
    }>(),
  );

  public static createAbsenderGeschaeftskunde = createAction(
    '[Absender Entities] Create absender geschaeftskunde.',
    props<{
      inhaberId: string,
      absenderDto: CreateAbsenderGeschaeftskundeRequestDTO,
    }>(),
  );

  public static createAbsenderSuccess = createAction(
    '[Absender Entities] Create absender privatperson successfully.',
    props<{ absenderId: string }>(),
  );

  public static createAbsenderPrivatpersonFailure = createAction(
    '[Absender Entities] Create absender privatperson failed.',
    props<{ error: any }>(),
  );

  public static createAbsenderGeschaeftskundeFailure = createAction(
    '[Absender Entities] Create absender geschaeftskunde failed.',
    props<{ error: any }>(),
  );

  public static readNextKundennummer = createAction(
    '[Absender Entities] Read next kundennummer.',
    props<{ inhaberId: string }>(),
  );

  public static readNextKundennummerSuccess = createAction(
    '[Absender Entities] Read next kundennummer successfully.',
    props<{ nextKundennummer: number }>(),
  );

  public static readNextKundennummerFailure = createAction(
    '[Absender Entities] Read next kundennummer failed.',
    props<{ error: any }>(),
  );
}
