import {createAction, props} from '@ngrx/store';
import {CreateProduktRequestDTO, UpdateProduktRequestDTO} from '../../openapi/fakturierung-openapi';


export class ProduktEntitiesActions {

  public static createProdukt = createAction(
    '[Create Produkt Dialog] Create produkt.',
    props<{
      inhaberId: string,
      produktDto: CreateProduktRequestDTO
    }>(),
  );

  public static updateProdukt = createAction(
    '[Update Produkt Dialog] Update produkt.',
    props<{
      inhaberId: string,
      produktId: string,
      produktDto: UpdateProduktRequestDTO,
    }>(),
  );

  public static updateProduktSuccess = createAction(
    '[Produkt Entities] Update produkt successfully.',
  );

  public static updateProduktFailure = createAction(
    '[Produkt Entities] Update produkt failed.',
    props<{ error: any }>(),
  );

  public static createProduktSuccess = createAction(
    '[Produkt Entities] Create produkt successfully.',
  );

  public static createProduktFailure = createAction(
    '[Produkt Entities] Create produkt failed.',
    props<{ error: any }>(),
  );

  public static readNextProduktnummer = createAction(
    '[Produkt Entities] Read next Poduktnummer.',
    props<{
      inhaberId: string;
    }>(),
  );

  public static readNextProduktnummerSuccess = createAction(
    '[Produkt Entities] Read next Poduktnummer.',
    props<{
      produktnummer: number;
    }>(),
  );

  public static readNextProduktnummerFailure = createAction(
    '[Produkt Entities] Read next Poduktnummer.',
    props<{
      error: any;
    }>(),
  );

}
