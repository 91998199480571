import {createReducer, on} from '@ngrx/store';
import {ProduktDialogState} from '../states/produkt-dialog.state';
import {ProduktDialogActions} from '../actions/produkt-dialog.actions';
import {ProduktEntitiesActions} from '../actions/produkt-entities.actions';
import {produktBerechnungsartenMock, produktEinheitenMock, produktUstSaetzeMock} from './produkt-dialog.mock';


export const initialCreateProduktDialogState: ProduktDialogState = {
  isCreateOpen: false,
  isUpdateOpen: false,
  produktId: undefined,
};

export const produktDialogReducer = createReducer(
  initialCreateProduktDialogState,

  on(
    ProduktDialogActions.open,
    (state, action) => ({
      ...state,
      isCreateOpen: true,
      produktId: action.produktId
    })
  ),

  on(
    ProduktDialogActions.openEdit,
    (state, action) => ({
      ...state,
      isUpdateOpen: true,
    })
  ),

  on(
    ProduktDialogActions.close,
    () => ({
      ...initialCreateProduktDialogState,
    })
  ),
);
