/**
 * Fakturierung
 * API für Fakturierung
 *
 * The version of the OpenAPI document: ${project.version}
 * Contact: support@landdata.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Typ des Absenders
 */
export enum AbsenderTypeDTO {
    Geschaeftskunde = 'Geschaeftskunde',
    Privatperson = 'Privatperson'
};

